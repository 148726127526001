*,
::before,
::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Inter;
}

.principal-setup-container {
  width: 100%;
  height: auto;
  min-height: 100vh;
  background-image: url("../../images/background-forgot.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
  overflow-x: hidden;
}

.steper-container {
  width: 662px;
  margin-bottom: 30px;
  height: auto;
  min-height: 666px;
  border-radius: 25px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
}

.steper-footer {
  height: 45px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(5, 114, 210, 0.1);
  color: #606061;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 37px; /* 308.333% */
}



@media only screen and (max-width:700px){
  .steper-container{
    width: 95%;
  }
}

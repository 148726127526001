*,
::after,
::before{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.sub-container{
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 9999;
    background-color: rgba(11, 183, 226, 0.1);
    overflow-y: hidden;
}
*,
::before,
::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Inter;
}

.menu-principal-container{
    width: 100%;
    height: auto;
    min-height: 150px;
    background-image: url("../../images/menu-background.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-sub-container{
    width: 100%;
    max-width: 1600px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo-sub-container img{
    width: 70%;
}

.menu-second-container{
width: 65%;
}

.web2-menu {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .web2-menu a {
    color: #fff;
    text-align: right;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    text-decoration: none;
  }

  .mobile-menu2 {
    display: none;
  }
  
  .drawer2 {
    width: 350px;
    height: 100%;
    position: fixed;
    top: 0;
    right: -350px;
    background-color: #1a5f9b;
    transition: right 0.3s ease;
  }
  
  .drawer2.open {
    right: 0px;
  }
  
  .drawer2 ul {
    list-style: none;
    padding: 0;
  }
  
  .drawer2 ul li {
    padding: 10px;
  }
  .drawer2-menu{
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px;
  }
  
  .drawer2-menu a{
    color: #d6d6d6dd;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    text-decoration: none;
  }
  
  .img2-menu{
    width: 60px;
  }
  .header2-menu{
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    align-items: center;
  }
  
  .closeBtn2 img{
    width: 18px;
  }


  @media only screen and (max-width:1730px){
    .box-login {
      margin-left: -20px;
    }
  }
  
  @media only screen and (max-width:1600px){
    .box-login {
      margin-left: 0px;
    }
    .menu-sub-container{
        padding-left: 20px;
        padding-right: 20px;
    }
  }
  
  @media only screen and (max-width:1500px){
    .web2-menu{
      display: none;
    }
    .menu-second-container{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 30px;
    }
    .mobile-menu2{
      display: block;
    }
    .logo-sub-container{
      padding-left: 50px;
      padding-right: 50px;
    }
  }
  
  @media only screen and (max-width:1115px){
    .logo-sub-container{
      padding-right: 0px;
    }
    .menu-second-container{
      padding-right: 10px;
    }
    .mobile-menu2{
      padding-top: 30px;
    }

  }
  
  @media only screen and (max-width:450px){
    .logo-sub-container{
      width: 100%;
      padding-left: 20px;
    
    }
    .menu-sub-container{
        padding-left: 5px;
        padding-right: 5px;
    }
    .logo-sub-container img{
      width: 250px;
      height: auto;
    }
    
  }
*,
::before,
::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Inter;
}

.login-principal-container {
  width: 100%;
  height: auto;
  min-height: 100vh;
  background-image: url("../../images/LoginBackground.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
  overflow-x: hidden;
}

.login-header {
  width: 100%;
  max-width: 1700px;
  padding-top: 50px;
}

.login-main {
  width: 100%;
  max-width: 1700px;
  display: flex;
  justify-content: center;
}

.login-main-title {
  width: 41%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
}
.login-main-title-container {
  width: 100%;
  padding-bottom: 150px;
}

.login-main-title-container h1 {
  width: 80%;
  color: #fff;
  text-align: left;
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.login-main-title-container p {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 85%;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.login-main-title-container ul li {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.login-white-line {
  width: 100%;
}

.login-white-line div {
  width: 60px;
  height: 3px;
  border-radius: 5px;
  background: #fff;
}

.login-main-form {
  width: 60%;
  display: flex;
  justify-content: center;
  padding-left: 110px;
  /* background-color: red; */
}
.login-main-form-container {
  width: 80%;
}

.login-tabs {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.login-fields {
  text-align: center;
  font-family: Inter;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.ul-login {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.card-login-setup {
  width: 100%;
  display: flex;
  justify-content: center;
}
.card-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.card-login-media {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-login-media-text {
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
}
.card-login-media-imgsocial {
  display: flex;
  gap: 20px;
  margin-top: 15px;
}

.login-footer {
  padding: 5px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-left: 110px;
  gap: 20px;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.container-terms-and-privacy {
  width: 80%;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: center;
  gap: 100px;
  padding-top: 250px;
}

.hide-terms{
  display: none;
}
.year-css{
  padding-bottom: 10px;
}

@media only screen and (max-width: 1750px) {
  .login-header {
    padding-left: 50px;
    padding-right: 50px;
  }
  .login-main {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media only screen and (max-width: 1600px) and (min-width: 1450px) {
  .login-main-form {
    padding-left: 0px;
  }
  .login-main-title {
    width: 50%;
  }
  .login-main {
    padding-right: 20px;
  }
  .login-main-form {
    width: 50%;
    display: flex;
    justify-content: center;
  }
}
@media only screen and (max-width: 1449px) {
  .login-principal-container {
    background-image: url("../../images/background-receivenda.png");
  }
  .login-header {
    display: flex;
    justify-content: center;
  }
  .login-main {
    flex-direction: column;
  }
  .login-main-title {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    padding-bottom: 0px;
  }
  .login-main-title-container {
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .login-main-title-container h1 {
    width: 100%;
    text-align: center;
  }
  .login-main-title-container p {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 80%;
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }
  .login-main-title ul {
    width: 80%;
    text-align: left;
  }
  .login-white-line {
    display: none;
  }
  .login-main-form {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
  }
  .login-main-form-container {
    background-color: white;
    padding: 50px;
    border-radius: 20px;
  }
  .login-footer {
    padding-left: 0px;
    justify-content: center;
  }

  .container-terms-and-privacy{
    display: none;
  }

  .hide-terms{
    display: flex;
    gap: 25px;
  }

  .ul-login{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .year-css{
    padding-bottom: 0px;
  }
}
@media only screen and (max-width: 900px) {
  .login-main-title {
    width: 100%;
  }

  .login-main-title-container h1 {
    width: 100%;
  }
  .login-main-title-container p {
    width: 95%;
  }
  .login-main-title ul {
    width: 95%;
  }
  .login-main-form {
    width: 95%;
  }
  .login-main-form-container {
    padding: 20px 5px;
    width: 100%;
  }
  .login-footer {
    padding-left: 0px;
    justify-content: center;
  }
}

@media only screen and (max-width: 450px) {
  .login-main {
    padding: 0px;
  }
  .login-main-title {
  }
  .login-main-form {
    width: 100%;
    justify-content: center;
    padding: 5px;
  }

  .hide-terms{
    display: flex;
    gap: 15px;
  }

  .login-footer{
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

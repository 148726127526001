.dashboard-principal-container {
  width: 100%;
  margin: 0px auto;
  /* max-width: 1920px; */
  height: auto;
  min-height: 92.9vh;
  max-height: 1032px;
  background-image: url("../../images/dashboard_background.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
  overflow-x: hidden;
}

.d-title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #0572d2;
  text-align: center;
  font-family: Inter;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.d-greenline-container {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.d-greenline {
  background: #06b576;
  width: 86px;
  height: 3px;
}

.d-main {
  width: 80%;
  height: auto;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding-bottom: 350px;
}

.d-main-info {
  width: 100%;
  height: auto;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.d-footer {
  width: 100%;
  height: auto;
  min-height: 60px;
  background-color: rgba(195, 217, 236, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #606061;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 37px;
  padding-top: 5px;
}

.skip {
  width: 386px;
  height: 50px;
  padding: 13px 10px;
  border-radius: 10px;
}

.home-dashboard-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-dashboard-title {
  color: #0572d2;
  text-align: center;
  font-family: Inter;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 40px;
  margin-bottom: 10px;
}

.home-dashboard-greenline {
  width: 90px;
  height: 3px;
  border-radius: 5px;
  background: #06b576;
}

/* .send-nda-containe-right {
  width: 460px;
  height: 202px;
  background-image: url("../../images/background-nda.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.203);
  display: flex;
  justify-content: center;
  align-items: center;
} */

.send-nda-containe-right {
  width: 380px;
  height: 202px;
  background-color: #0572d2cc;
  border-radius: 10px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.203);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.send-nda-containe-left {
  width: 460px;
  height: 202px;
  background-image: url("../../images/background-nda2.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.203);
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-info-left {
  display: flex;
  justify-content: flex-end;
}

.center-info {
  display: flex;
  justify-content: center;
}

.center-info2 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 50px;
}

.center-info-right {
  display: flex;
  justify-content: flex-start;
}

.left-nda {
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
}

.right-nda {
  width: 71%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 30px;
}

.right-nda-title {
  color: #fff;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.right-nda-text {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
}

.btn-offer {
  width: 63px;
  height: 23px;
  border-radius: 10px;
  background: #fff;
  color: #f00;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: line-through;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
}

@media only screen and (max-width: 1533px) {
  .d-main {
    flex-direction: column;
  }
}

@media only screen and (max-width: 1021px) {
  .d-main {
    padding-bottom: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .home-dashboard-container {
  }
  .mobile-home-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }
  .center-info-left {
    display: flex;
    justify-content: center;
  }

  .center-info-right {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 440px) {
  .d-main {
    margin-bottom: 10px;
    width: 100%;
  }

  .d-greenline {
    width: 86px;
    height: 3px;
    margin-top: -5px;
    margin-bottom: 25px;
  }

  .send-nda-containe-left {
    margin-top: 40px;
  }

  .center-info2 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }
}

.login-card-container {
  width: 100%;
  height: auto;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logincard-title {
  color: #0572d2;
  text-align: center;
  font-family: Inter;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
}

.logincard-subttitle {
  color: #606061;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px;
  margin-top: 20px;
}
.logincard-line {
  width: 57px;
  height: 3px;
  background: #06b576;
}

.logincard-from {
  width: 80%;
}

.green-line {
  width: 100px;
  height: 3px;
  background-color: #06b576;
  margin-bottom: 20px;
}

.login-record-forgot {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.logincard-label {
  color: #383e45;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.forgot-password-title {
  color: #06b576;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.label-title2 {
  color: #0572d2;
  text-align: center;
  font-family: Inter;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.logincard-email {
  color: #606061;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
}

.send-me-otp {
  background-color: #06b576;
  color: white;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
}

.complete-registration{
color: #0572D2;
text-align: center;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

@media screen and (max-width: 440px) {
  .login-card-container {
    width: 100%;
  }
}

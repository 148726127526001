.timer-container {
  width: 100%;
  height: auto;
  min-height: 200px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.countdown {
  display: flex;
}

.countdown .time {
  background: linear-gradient(rgba(180, 180, 180, 1), rgba(255, 255, 255, 1));
  border: 2.5px solid rgba(6, 181, 118, 1);
  border-radius: 10px;
  font-weight: bold;
  margin: 0 -5px;
}

.countdown .time div {
  width: 120px;
  height: 110px;
  display: grid;
  place-items: center;
  color: #0572d2;
  text-align: center;
  text-shadow: 0px 2px 0px #fff;
  font-family: Inter;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

p {
  margin: 6px;
}

.timer-title {
  width: 75%;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 12px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.section-day {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  margin-top: 10px;
}

.line-timer {
  width: 42px;
  height: 1px;
  transform: rotate(90deg);
  background: #D9D9D9;
  margin-top: 50px;
}


@media only screen and (max-width: 991.98px) {
  .timer-title {
    width: 100%;
    margin-top: 30px;
  }

  .countdown .time div {
    width: 75px;
    height: 80px;
    font-size: 40px;
  }

  .line-timer {
    width: 42px;
    height: 3px;
    transform: rotate(90deg);
    background: #D9D9D9;
    margin-top: 45px;
  }
  
}

.preview-principal-container {
  width: 100%;
  height: auto;
  min-height: 92.9vh;
  background-image: url("../../images/dashboard_background.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
  overflow-x: hidden;
}

.preview-card {
  width: 614px;
  height: auto;
  border-radius: 25px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
}

.preview-card-title {
  color: #383e45;
  text-align: center;
  font-family: Inter;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
}

.greenline-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview-linegreen {
  width: 57px;
  height: 3px;
  border-radius: 5px;
  background: #06b576;
}

.preview-iframe{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color:#68686892;
    padding-top: 5px;
    padding-bottom: 5px;
}

.preview-buttons-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.signature-con{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.preview-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(5, 114, 210, 0.15);
  height: 60px;
  color: #606061;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 37px; /* 308.333% */
}

.signature-preview-container{
}
@media only screen and (max-width:640px){
  .preview-card {
    width: 90%;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width:460px){
  .preview-card {
    width: 98%;
    margin-bottom: 20px;
  }
}
.eaddress-principal-container {
  width: 100%;
  height: auto;
  min-height: 92.9vh;
  background-image: url("../../images/dashboard_background.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
  overflow-x: hidden;
}
.step-card-container {
  width: 90%;
  height: auto;
  min-height: 450px;
  margin-bottom: 10px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.step-card-title {
  width: 100%;
  text-align: center;
  font-size: 33px;
  color: #383e45;
  font-family: Inter;
}

.step-card-line {
  background-color: #06b576;
  width: 57px;
  height: 3px;
  margin: 8px 0px;
  margin-bottom: 30px;
}

.step-card-radio-buttons {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-around;
  margin: 10px 0px;
}

.step-card-radio {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-around;
  flex-wrap: wrap;
}
.step-card-radio1 {
  font-size: 16px;
  font-family: Inter;
  color: #383e45;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.step-card-radio2 {
  font-size: 16px;
  font-family: Inter;
  color: #383e45;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.step-button-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.eaddress-card-container {
  width: 590px;
  min-height: 583px;
  height: auto;
  border-radius: 25px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}
.google-autocomplete {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 5px;
}
.search {
  position: relative;
  width: 74%;
}
.eaddress-footer {
  width: 100%;
  height: auto;
  min-height: 60px;
  background-color: rgba(195, 217, 236, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #606061;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 37px;
  padding-top: 5px;
}

@media only screen and (max-width:630px){
  .eaddress-card-container{
    width: 95%;
  }
}
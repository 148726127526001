.chat-principal-container {
	width: 100%;
	margin: 0px auto;
	height: auto;
	min-height: 92.9vh;
	max-height: 1032px;
	/* background-image: url("../../images/dashboard_background.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover; */
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	overflow: auto;
}

.chat-title-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #0572d2;
	text-align: center;
	font-family: Inter;
	font-size: 33px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.chat-view {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.chat-greenline-container {
	width: 100%;
	height: 70px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.chat-greenline {
	background: #06b576;
	width: 86px;
	height: 3px;
}

.chat-body {
	width: 100%;
	max-width: 1520px;
	height: auto;
	min-height: 700px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 20px;
	padding-bottom: 100px;
}

.chat-blue-subtitle {
	color: #0572d2;
	text-align: center;
	font-family: Inter;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.chat-blue-subtitle2 {
	color: #0572d2;
	text-align: center;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.profile-container {
	border-radius: 10px;
	background: #fff;
	height: auto;
	min-height: 250px;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
}

.profile-image-container {
	width: 100%;
	height: auto;
	min-height: 5px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

.profile-image-container img {
	position: absolute;
	width: 154px;
	height: 154px;
	border-radius: 50%;
	object-fit: cover;
}
.profile-content {
	padding-top: 80px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.profile-name {
	color: #383e45;
	text-align: center;
	font-family: Inter;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.profile-email {
	color: #606061;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.profile-phone {
	color: #0572d2;
	text-align: center;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.button-document {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #ffb800;
	border: 1px solid #0572d2;
	width: 48px;
	height: 48px;
	border-radius: 100%;
}

.button-chat {
	width: 48px;
	height: 48px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.button-chat img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.entity-chat-container {
	height: auto;
	min-height: 71px;
	border-radius: 19px;
	background: #f8f8f8;
}

.address-chat-container {
	height: auto;
	min-height: 174px;
	border-radius: 19px;
	background: #f8f8f8;
}

.card-chat-title {
	color: #0572d2;
	text-align: center;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.card-chat-subtitle {
	color: #606061;
	text-align: center;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.img-card-profile {
	width: 35px;
	height: 35px;
	border-radius: 100%;
}

.img-card-profile img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.active-circle {
	width: 10px;
	height: 10px;
	background-color: #0fe16d;
	border-radius: 100%;
	position: absolute;
	top: 33px;
	left: 42px;
}

.card-header-title {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
}

.subtitle-blue-20px {
	color: #0572d2;
	text-align: center;
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.media-container {
}

.header-circle-container {
	width: 30px;
	height: 30px;
	background-color: #e1e1e1;
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.media-title {
	color: rgba(56, 62, 69, 0.5);
	text-align: center;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 300;
	line-height: normal;
}

.active-media-title {
	color: #383e45;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.header-media {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
	cursor: pointer;
}

.chat-room-container {
	width: 100%;
	height: auto;
	min-height: 680px;
	max-height: 680px;
	background-color: #fff;
	overflow-x: hidden;
	overflow-y: auto;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-right: 5px;
}

.chat-room-writer {
	width: 98%;
	height: auto;
	min-height: 90px;
	background: #fff;
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.13);
	margin-top: 5px;
	padding-left: 10px;
	padding-right: 10px;
}

.document-chat-container {
	width: 100%;
	height: auto;
	max-height: 780px;
	display: flex;
	justify-content: space-evenly;
	gap: 5px;
	flex-wrap: wrap;
	padding: 5px;
	padding-right: 5px;
	overflow-x: hidden;
	overflow-y: auto;
}

.card-media-container {
	width: 130px;
	height: 160px;
	border: 1px solid #e8e8e8;
}

.card-media-container img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.card-document-container {
	width: 100%;
	height: auto;
	min-height: 52px;
	border-radius: 10px;
	background: #fff;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
	margin-top: 5px;
	margin-bottom: 5px;
}

.documment-date {
	color: #0572d2;
	text-align: right;
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.document-time {
	color: #383e45;
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 300;
	line-height: normal;
}

.d-footer {
	width: 100%;
	height: auto;
	min-height: 60px;
	background-color: rgba(195, 217, 236, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	color: #606061;
	text-align: center;
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 37px;
	padding-top: 5px;
}


@media (max-width: 768px) {
	.hide-chat {
	  display: none !important;
	}

	.chat-room-container{
		min-height: 600px;
		max-height: 600px;
		width: 100%;
	}

	.chat-room-writer {
		width: 100%;
		margin-top: 10px;
		padding: 0px 10px;
	}
  }
.nda-principal-container {
  width: 100%;
  height: auto;
  min-height: 92.9vh;
  background-image: url("../../images/dashboard_background.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
  overflow-x: hidden;
}

.nda-profile-container {
  width: 662px;
  height: 795px;
  border-radius: 25px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nda-profile-footer {
  width: 100%;
  height: auto;
  min-height: 60px;
  background-color: rgba(195, 217, 236, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #606061;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 37px;
  padding-top: 5px;
}

.nda-image-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.nda-profile-title {
  color: #383e45;
  text-align: center;
  font-family: Inter;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 80px;
  margin-bottom: 10px;
}

.nda-profile-greenline {
  width: 57px;
  height: 3px;
  border-radius: 5px;
  background: #06b576;
}

.nda-profile-form {
  width: 80%;
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.select-contact-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nda-contact-list-text {
  color: #06b576;
  text-align: center;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.nda-contact-list-text:hover {
  text-decoration: underline;
  cursor: pointer;
}

.nda-or {
  color: #383e45;
  text-align: center;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px; /* 95.455% */
}

.nda-profile-blueline {
  width: 89px;
  height: 1px;
  background: #0572d2;
}

.button-nda-send {
  width: 542px;
  height: 50px;
  border-radius: 10px;
  background: #0572d2;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
}

.button-nda-send:hover {
  cursor: pointer;
  border: 2px solid rgba(52, 52, 150, 0.347);
}

.text-button-nda {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.nda-contact-container {
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nda-contact-list-profile {
  width: 100%;
  height: 550px;
  margin-top: 0px;
  margin-bottom: 50px;
  overflow-y: auto;
}

.image-contact-container {
  width: 40px;
  height: 40px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid #06b576;
}

.text-contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nda-contact-image-name {
  display: flex;
  gap: 20px;
  padding: 12px;
  cursor: pointer;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}

.check-container {
  width: 30px;
  height: 30px;
  margin-top: 1px;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.check-container2 {
  width: 24px;
  height: 24px;
  margin-top: 7px;
  border-radius: 100%;
  border: 1px solid black;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step3-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.entity-info-container {
  margin-top: 15px;
  width: 85%;
  height: 150px;
  border-radius: 10px;
  border: 1px solid #dadada;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
}

.entity-info-title {
  color: #0572d2;
  text-align: center;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
}

.entity-info-subtitle {
  color: #06b576;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-top: 10px;
  margin-bottom: 10px;
}

.entity-info-address {
  text-align: center;
  color: #606061;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-top: 5px;
}

.recipient-container {
  margin-top: 15px;
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.recipient-title {
  width: 100%;
  color: #383e45;
  text-align: center;
  font-family: Inter;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.recipient-contact-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.recipient-contact-info .co-img {
  margin-top: 10px;
  border-radius: 81px;
  border: 2px solid #ececec;
  width: 81px;
  height: 81px;
}

.contact-info-data {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fullname-contact {
  margin-top: 10px;
  color: #383e45;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.phone-contact {
  margin-top: 5px;
  color: #606061;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.contact-img-container{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.container-recipient-buttons{
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 80px;
  gap: 15px;
}


@media only screen and (max-width: 626px) {
  .nda-profile-container {
    width: 90%;
    height: auto;
    min-height: 500px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .nda-profile-form {
    width: 90%;
  }

  .button-nda-send {
    width: 90%;
    height: auto;
    min-height: 50px;
  }
}

@media only screen and (max-width: 490px) {
  .nda-profile-container {
    width: 90%;
    height: auto;
    min-height: 500px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .nda-profile-form {
    width: 90%;
  }

  .button-nda-send {
    width: 90%;
    height: auto;
    min-height: 50px;
  }
}

.buttons-type-container{
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.button-type{
width: 200px;
padding: 10px 15px;
display: flex;
justify-content: center;
align-items: center;
gap: 5px;
border-radius: 10px;
border: 1px solid #0572D2;
}

.bg-header {
  background-color: rgba(5, 114, 210, 1);
  height: auto;
  min-height: 73.54px;
}

.header-container {
  display: flex;
  width: 100%;
  padding: 0px 20px;
}

.header-brand {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 190px;
}

.offcanvas {
  background-color: white;
}

.nav-container {
  display: flex;
  gap: 20px;
  margin-top: 4px;
  padding-left: 40px;
}

.nav-container a {
  font-size: 19px;
  text-decoration: none;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.menu-icon {
  color: white;
  width: 19px;
  height: 19px;
}

.user-avtar {
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid white;
}

.dropdown-header-container2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-header-container1 {
  display: none;
}

.dropdown-header1 {
  color: #fff;
  text-align: right;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dropdown-header2 {
  margin-top: -6px;
}

.canvas-title {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 3px solid rgba(128, 128, 128, 0.2);
  padding: 15px;
  color: #383E45;
  font-family: Inter;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.canvas-body {
  width: 100%;
  height: 75%;
}

.canvas-body-name{
  color: #383E45;
  text-align: center;
  font-family: Inter;
  font-size: 25px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  padding-top: 10px;
  padding-bottom: 5px;
}

.canvas-body-account{
  color: #383E45;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 0px;
  padding-bottom: 5px;
}

.canvas-body-direction{
  color: rgba(0, 0, 0, 0.6);
  text-align: left;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 0px;
  padding-left: 15px;
  padding-bottom: 5px;
}

.canvas-footer {
  width: 100%;
  height: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding-bottom: 10px;
}

.canvas-footer-button{
width: 90%;
display: flex;
justify-content: space-between;
align-items: center;
height: 50px;
background-color: rgba(96, 96, 97, 0.20);
padding: 5px 20px;
color: rgba(0, 0, 0, 0.6);
text-align: left;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
padding-top: 0px;
padding-left: 15px;
padding-bottom: 5px;
border-radius: 10px;
cursor: pointer;
}

.canvas-ul-container{
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 30px 15px;
}

.canvas-ul-container ul {
display: flex;
flex-direction: column;
gap: 20px;
}

.canvas-ul-container ul li{
  color: #606061;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
}

.canvas-info-title{
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px 50px;
  border-top: 1px solid rgba(128, 128, 128, 0.621);
  gap: 20px;
}

.canvas-info-title div{
  color: #0572D2;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

@media screen and (max-width: 600px) {
  .nav-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }

  .dropdown-header-container2 {
    display: none;
  }

  .dropdown-header-container1 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.cpassword-principal-container {
  width: 100%;
  height: auto;
  min-height: 92.9vh;
  background-image: url("../../images/dashboard_background.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
  overflow-x: hidden;
}

.cpassword-main-container {
  width: 662px;
  height: 673px;
  border-radius: 25px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cpassword-form {
  width: 90%;
}

.cpassword-title {
  color: #383e45;
  text-align: center;
  font-family: Inter;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.cpassword-footer {
  width: 100%;
  height: auto;
  min-height: 60px;
  background-color: rgba(195, 217, 236, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #606061;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 37px;
  padding-top: 5px;
}

@media only screen and (max-width:440px){
  .cpassword-main-container{
    width: 98%;
  }
}

.document-card-container {
  width: 100%;
  height: auto;
  min-height: 111px;
  display: flex;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
}

.first {
  width: 10%;
  background: rgba(196, 196, 196, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
}

.second {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 5px;
  padding-left: 10px;
}

.second-step-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.second-step-info {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
}

.second-step-container {
  display: flex;
}

.second-name {
  color: #383e45;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.second-company {
  color: #606061;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.second-type {
  color: #06b576;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.second-doc-type {
  color: #60606196;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.third {
  width: 15%;
  border-radius: 10px;
  background: #fff;
}

.third-read-container {
  width: 100%;
  border-radius: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.third-data-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 10px;
  padding-top: 10px;
  color: #606061;
  text-align: right;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.third-img{
    display: flex;
    justify-content: center;
    align-items: end;
    padding-top: 5px;
}
.delete-principal-container {
  width: 100%;
  height: auto;
  min-height: 100vh;
  background-image: url("../../images/background-settings.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
  overflow-x: hidden;
}

.delete-logo {
  width: 100%;
  height: auto;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-img-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.delete-title {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 33px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 20px;
}

.white-line {
  width: 89px;
  height: 1px;
  background: #fff;
}

.white-line-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-top: 10px;
}

.buttons-delete-cont {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttons-dt-cont {
  width: 100%;
  margin-top: 40px;
  height: auto;
  min-height: 150px;
  border-radius: 25px;
  background: rgba(255, 255, 255, 0.11);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.buttons-dt-cont button {
  width: 40%;
}

.delete-text {
  width: 100%;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
}

.delete-footer {
  width: 100%;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.15);
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-remove-container {
  width: 662px;
  height: auto;
  min-height: 170px;
  border-radius: 25px;
  background: rgba(255, 255, 255, 0.11);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.card-remove-container div {
  width: 89%;
}

.remove-text{
    color: #FFF;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
}
.remove-title {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media only screen and (max-width:720px){
    .card-remove-container {
        width: 95%;
        padding: 20px 15px;
    }

    .card-remove-container div {
        width: 95%;
      }
}

*,
::before,
::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Inter;
}

.homepage-container {
  width: 100%;
  height: auto;
  min-height: 100vh;
  background-image: url("../../images/WebsiteConfidencialBeev1.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
  overflow-x: hidden;
  padding-left: 100px;
  padding-right: 100px;
}

.menu-logo-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
  width: 100%;
  max-width: 1600px;
}
.logo-home-container {
  width: auto;
}

.menu-home-container {
  width: 65%;
}

.web-menu {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.web-menu a {
  color: #fff;
  text-align: right;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none;
}

.box-waitlist {
  width: 148px;
  height: 65px;
  border-radius: 10px;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-login {
  margin-left: -50px;
  width: 148px;
  height: 65px;
  border-radius: 10px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-login {
  color: #0572d2;
}

.main-homepage-content {
  width: 100%;
  height: auto;
  min-height: 70px;
  max-width: 1600px;
  display: flex;
  margin-top: 0px;
}

.main-homepage-image{
  width: 40%;
}

.main-homepage-title{
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 30px;
}

.main-homepage-title div{
  width: 90%;
  color: #FFF;
  text-align: right;
  font-family: Inter;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* text-transform: uppercase; */
}

.main-homepage-title p{
  width: 90%;
  color: #FFF;
  text-align: right;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 30px;
}

.main-homepage-title h2{
  width: 60%;
  color: #FFF;
  text-align: right;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  margin-top: 20px;
}

.store-images{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 5px;
}

.homepage-social-media{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  gap: 20px;
  margin-top: 30px;
}

.footer-homepage-content{
  width: 100%;
  height: 57px;
  background-color: rgba(225, 225, 225, 0.15);
  color: #616161;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 37px;
  display: flex;
  justify-content: center;
  align-items: center; 
}

.mobile-menu {
  display: none;
}

.drawer {
  width: 350px;
  height: 100%;
  position: fixed;
  top: 0;
  right: -350px;
  background-color: #ffffff;
  transition: right 0.3s ease;
}

.drawer.open {
  right: 0px;
}

.drawer ul {
  list-style: none;
  padding: 0;
}

.drawer ul li {
  padding: 10px;
}
.drawer-menu{
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
}

.drawer-menu a{
  color: #000000dd;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none;
}

.img-menu{
  width: 60px;
}
.header-menu{
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  align-items: center;
}

.closeBtn img{
  width: 18px;
}

@media only screen and (max-width:1730px){
  .box-login {
    margin-left: -20px;
  }
}

@media only screen and (max-width:1600px){
  .box-login {
    margin-left: 0px;
  }
}

@media only screen and (max-width:1500px){
  .homepage-container {
    background-image: url(../../images/DownloadWeb-Background2.png);
    padding: 0px;
  }
  .web-menu{
    display: none;
  }
  .menu-home-container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 30px;
  }
  .mobile-menu{
    display: block;
  }
  .menu-logo-container{
    padding-left: 50px;
    padding-right: 50px;
  }
  .main-homepage-content{
    padding-left: 50px;
    padding-right: 30px;
  }

  .main-homepage-title{
    padding-left: 20px;
  }
}

@media only screen and (max-width:1115px){
  .menu-logo-container{
    padding-right: 0px;
  }
  .menu-home-container{
    padding-right: 10px;
  }
  .mobile-menu{
    padding-top: 30px;
  }
 .main-homepage-content{
  flex-direction: column-reverse;
  
 }
 .main-homepage-title{
  width: 100%;
  align-items: center;
  padding-right: 0px;
  margin-top: 50px;
 }
 .main-homepage-image{
  width: 100%;
 }


.main-homepage-title div{
  width: 100%;
  text-align: center;
  font-family: Inter;
  font-size: 50px;
}

.main-home-principal-title1{
  padding-top: 0px;
}
.main-home-principal-title2{
  padding-top: 0px;
}
.main-home-principal-title3{
}

.main-homepage-title p{
  width: 85%;
  text-align: center;
  margin-top: 30px;
}

.main-homepage-title h2{
  width: 60%;
  text-align: center;
}

.store-images{
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 15px;
}

.main-homepage-image{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.homepage-social-media{
  justify-content: center;
}
}

@media only screen and (max-width:450px){
  .menu-logo-container{
    width: 100%;
    padding-left: 20px;
  
  }

  .main-homepage-content{
    padding-right: 10px;
    padding-left: 10px;
  }
  .logo-home-container img{
    width: 250px;
    height: auto;
  }

  .main-homepage-image{
    width: 100%;
  }
  .main-homepage-image img{
    width: 410px;
    height: auto;
  }

  .main-homepage-title{
    padding-left: 0px;
    font-size: 42px;
  }
  .main-homepage-title div{
    font-size: 40px;
  }
  .main-homepage-title h2 {
    width: 100%;
  }
  
}



.eentity-principal-container {
  width: 100%;
  height: auto;
  min-height: 92.9vh;
  background-image: url("../../images/dashboard_background.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
  overflow-x: hidden;
}

.eentity-main {
  width: 662px;
  height: 850px;
  border-radius: 25px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.eentity-title {
  color: #383e45;
  text-align: center;
  font-family: Inter;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
}

.eentity-greenline-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.eentity-greenline {
  width: 57px;
  height: 3px;
  border-radius: 5px;
  background: #06b576;
}

.eentity-card-container {
  width: 85%;
  min-height: 70px;
  height: auto;
}

.eentity-card {
  width: 100%;
  border-radius: 19px;
  background: #f8f8f8;
  padding-top: 10px;
  padding-bottom: 10px;
}

.eentity-card:hover {
    cursor: pointer;
    background: #f0eeee;
}

.e-card-greenline {
  width: 100%;
  height: 1px;
  background: #06b576;
  margin-top: 5px;
  margin-bottom: 5px;
}

.eentity-card-title {
  color: #0572d2;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.eentity-card-content {
  color: #606061;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  padding-top: 10px;
}

.eentity-card-content-agree {
  color: #06b576;
  text-decoration-line: underline;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  padding-top: 10px;
}

.eentity-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
}

.eentity-show-signature {
  width: 90%;
  height: auto;
  min-height: 242px;
  border: 1px dotted rgb(190, 190, 190);
  border-radius: 5px;
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.eentity-show-signature img{
  width: 100%;
  height: auto;
  min-height: 242px;
}

.eentity-footer {
  width: 100%;
  height: auto;
  min-height: 60px;
  background-color: rgba(195, 217, 236, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #606061;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 37px;
  padding-top: 5px;
}

@media only screen and (max-width:630px){
  .eentity-main{
    width: 95%;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}
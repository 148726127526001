.card-container {
	width: 100%;
	height: auto;
	min-height: 96px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 12.911px;
	background: #fff;
	box-shadow: 0px 0px 12.911px 0px rgba(0, 0, 0, 0.15);
}

.card-container:hover {
	box-shadow: 0px 0px 12.911px 0px rgba(0, 0, 0, 0.25);
}

.image-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.chat-img {
	width: 75px;
	height: 75px;
	border-radius: 100%;
	background: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.chat-img img {
	width: 100%;
	height: 100%;
	border-radius: 100%;
	object-fit: cover;
}

.chat-user {
	color: #383e45;
	font-family: Inter;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.chat-info-notifications {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
	margin-top: 10px;
}

.time-chat{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    padding-right: 25px;
    height: 100%;
    min-height: 70px;
}
.time-text {
	color: #383e45;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.modal-background {
  border-radius: 5px;
  background: linear-gradient(7deg, #0572d2 10.61%, #4fb9ff 89.39%);
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.15);
  width: 500px;
  height: auto;
  min-height: 335px;
}

.modal-background2 {
  border-radius: 5px;
  background: linear-gradient(7deg, #0572d2 10.61%, #4fb9ff 89.39%);
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.15);
  width: 500px;
  height: auto;
  min-height: 335px;
}

.modal-background3 {
  border-radius: 5px;
  background: linear-gradient(7deg, #dadada 10.61%, #ffffff 89.39%);
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.15);
  width: 500px;
  height: auto;
  min-height: 335px;
}


.notification-img {
  display: flex;
  justify-content: center;
}

.notification-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.notification-title {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 5px;
}

.notification-white-line {
  width: 89px;
  height: 1px;
  background: #fff;
  margin-top: 10px;
  margin-bottom: 15px;
}

.notification-green-line {
  width: 89px;
  height: 1px;
  background: #06b576;
  margin-top: 10px;
  margin-bottom: 15px;
}

.notification-message {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.otp-txt {
  margin: 5px;
  width: 55px;
  height: 60px;
  text-align: center;
  color: #1f4e61;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.re-send-button {
  color: #06b576;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.footer-modal2 {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.footer-cancel {
  display: flex;
  width: 150px;
  padding: 13px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid white;
  color: white;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.footer-accept {
  display: flex;
  width: 150px;
  padding: 13px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: white;
  color: #0572d2;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

@media only screen and (max-width: 440px) {
  .modal-background {
    width: 100%;
    height: 335px;
  }

  .modal-background2 {
    width: 100%;
    height: 450px;
  }

  .modal-background3 {
    width: 100%;
    height: 450px;
  }
}

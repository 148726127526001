.dc-principal-container {
  width: 390px;
  height: 160px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.15);
  display: flex;
}

.dc-image {
  width: 30%;
  height: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dc-info {
  width: 70%;
  height: 100%;
}

.dc-confirm {
  width: 100%;
  text-align: end;
  padding: 7px;
}

.dc-title {
  color: #0572d2;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 1px;
  margin-left: 5px;
}

.dc-subtitle {
  color: #606061;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-top: 10px;
  margin-left: 5px;
}

.dc-button {
margin-top: 10px;
}

.dc-button button {
  width: 176px;
  height: 40px;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

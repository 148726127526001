.chat-outmessage-container {
	width: auto;
	min-width: none;
	max-width: 400px;
	border-radius: 10px;
	background: #daf4ea;
	padding: 15px;
	margin-top: 5px;
	margin-bottom: 5px;
}

.chat-inmessage-container {
	width: auto;
	min-width: none;
	max-width: 400px;
	border-radius: 10px;
	background: #d9eaf8;
	padding: 15px;
	margin-top: 5px;
	margin-bottom: 5px;
}

.title-sender {
	color: #06b576;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.title-time {
	color: #383e45;
	text-align: right;
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 300;
	line-height: 10px; 
}

.settings-principal-container {
  width: 100%;
  height: auto;
  min-height: 92.9vh;
  background-image: url("../../images/dashboard_background.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
  overflow-x: hidden;
}

.main-settings-container {
  width: 662px;
  height: auto;
  min-height: 850px;
  border-radius: 25px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
}

.settings-title {
  width: 100%;
  color: #383e45;
  text-align: center;
  font-family: Inter;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
}

.settings-greenline-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.settings-greenline {
  width: 57px;
  height: 3px;
  border-radius: 5px;
  background: #06b576;
}

.logout-settings,
.desactive-settings,
.socialmedia-settings,
.preferences-settings,
.account-settings {
  width: 542px;
  border-radius: 10px;
  background: #f8f8f8;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.settings-option-title {
  color: #0572d2;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 10px 15px;
}

.card-option-settings {
  width: 100%;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-option-settings:hover {
background-color: #60606129;
cursor: pointer;
border-radius: 10px;
}

.card-option-settings div {
  color: #606061;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.boder-t{
    border-top: 1px solid #E0E6F3;;
}

.socialmedia-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.footer-settings {
  width: 100%;
  height: 60px;
  background-color: rgba(5, 114, 210, 0.1);
  color: #606061;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rotate-0{
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
}

.rotate-90{
    transform: rotate(90deg);
    transition: transform 0.3s ease-in-out;
}

.show-card{
    display: none;
}

.show-card {
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 0.10s ease-in-out, height 0.10s ease-in-out;
}

.card-option-settings {
  opacity: 1;
  height: auto;
}

@media only screen and (max-width:630px){
  .main-settings-container{
    width: 95%;
    margin-bottom: 10px;
  }

  .logout-settings,
.desactive-settings,
.socialmedia-settings,
.preferences-settings,
.account-settings {
  width: 100%;
}
}

@media only screen and (max-width:440px){
  .main-settings-container{
    width: 98%;
    margin-bottom: 10px;
  }

  .logout-settings,
.desactive-settings,
.socialmedia-settings,
.preferences-settings,
.account-settings {
  width: 100%;
}
}
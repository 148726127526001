.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 1; 
    transition: opacity 1s ease-in-out;
  }
  
  .backdrop.show {
    opacity: 1;
  }
  
  .backdrop.fade {
    opacity: 0;
  }
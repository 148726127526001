.viewDocument-principal-container {
  width: 100%;
  height: auto;
  min-height: 92.9vh;
  background-image: url("../../images/dashboard_background.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
  overflow-x: hidden;
}

.viewDocument-main {
  width: 625px;
  height: auto;
  min-height: 400px;
  border-radius: 25px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: flex-start;

}

.view-princial-container {
  width: 95%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}



.view-card-container {
  width: 90%;
  height: auto;
  min-height: 200px;
  margin-bottom: 10px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.view-card-title {
  width: 100%;
  text-align: center;
  font-size: 33px;
  color: #383e45;
  font-family: Inter;
}

.view-card-line {
  background-color: #06b576;
  width: 57px;
  height: 3px;
  margin: 8px 0px;
  margin-bottom: 10px;
}


.view-button-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.button-view {
  width: 266px;
}


.view-preview {
  background-color: #f3f3f3;
  width: 100%;
  height: 600px;
}
.viewDocument-footer{
  width: 100%;
  height: auto;
  min-height: 60px;
  background-color: rgba(195, 217, 236, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #606061;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 37px;
  padding-top: 5px;
}

.view-signature-img{
  border: 1px dotted black;
}

.view-signature-container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iframe-document{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  padding-top: 7px;
  padding-bottom: 10px;
}

.iframe-document img{
  width: 97%;
  margin-top: 3px;
}

@media only screen and (max-width: 440px) {


  .view-button-container button {
    width: 180px;
  }

  .view-preview .iframe {
    background-color: transparent;
  }
}

.receive-principal-container {
  width: 100%;
  height: auto;
  min-height: 100vh;
  background-image: url("../../images/background-receivenda.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
  overflow-x: hidden;
}

.receive-nda-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.receive-nda-name {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.receive-nda-title {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 33px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.receive-greenline {
  width: 86px;
  height: 3px;
  border-radius: 5px;
  background: #06b576;
}

.receive-buttom-container {
  width: 662px;
  height: 150px;
  border-radius: 25px;
  margin-top: 10px;
  background: rgba(255, 255, 255, 0.11);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.receive-text-container {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; 
}

.receive-button{
width: 80%;
}

.receive-nda-footer {
  width: 100%;
  height: auto;
  min-height: 60px;
  background-color: rgba(255, 255, 255, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 37px; /* 308.333% */
  padding-top: 5px;
}

@media only screen and (max-width:630px){
  .receive-buttom-container{
    width: 95%;
  }
}
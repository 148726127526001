.modal-open {
    overflow: hidden;
  }
  
  .modal-backdrop {
    z-index: 1040;
  }
  
  .modal {
    z-index: 1050;
  }

.fade.modal.show{
display: flex;
justify-content: center;
align-items: center;
}
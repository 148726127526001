
  .container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    padding: 0px;
  }
  
  .sigContainer {
    width: 100%;
    height: 100%;
  }
  
  .sigPad {
    width: 100%;
    height: 100%;
    background-color: transparent;
  }
  
  .buttons {
    width: 100%;
    height: 30px;
  }
  
  .sigImage {
    background-size: 200px 50px;
    width: 200px;
    height: 50px;
    background-color: transparent;
  }
  
*,
::before,
::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Inter;
}

.verifyEmail-principal-container {
  width: 100%;
  height: auto;
  min-height: 100vh;
  background-image: url("../../images/background-forgot.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
  overflow-x: hidden;
}

.verify-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 93.5vh;
}

.verify-img-container {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.verify-container {
  width: 100%;
  height: auto;
  min-height: 600px;
  border-radius: 25px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.email-device {
  width: 100%;
  height: auto;
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.verify-container-title {
  color: #383e45;
  text-align: center;
  font-family: Inter;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.verify-container-content {
  color: #606061;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
}

.forgot-green-line {
  width: 57px;
  height: 3px;
  border-radius: 5px;
  background: #06b576;
  margin-top: 30px;
}

.verify-green-line2 {
    width: 89px;
    height: 3px;
    border-radius: 5px;
    background: #06b576;
    margin-top: 15px;
  }

.verify-footer {
  width: 100%;
  height: auto;
  min-height: 60px;
  background-color: rgba(5, 114, 210, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.verify-footer-title {
  color: #606061;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 37px;
}

@media screen and (max-width: 630px) {
  .verify-main-container {
    width: 95%;
    min-height: 93.5vh;
  }

  .verify-main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 600px) {
  .verify-main-container {
    width: 98%;
    min-height: 93.5vh;
  }

  .verify-main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

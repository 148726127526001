.edit-principal-container {
  width: 100%;
  height: auto;
  min-height: 92.9vh;
  background-image: url("../../images/dashboard_background.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
  overflow-x: hidden;
}

.label {
  color: #0572d2;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.edit-container {
  width: 662px;
  height: 858px;
  border-radius: 25px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.edit-title {
  color: #383e45;
  text-align: center;
  font-family: Inter;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 30px;
}

.edit-greenline-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.edit-greenline {
  width: 57px;
  height: 3px;
  border-radius: 5px;
  background: #06b576;
}

.form-edit {
  width: 90%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.edit-footer {
  width: 100%;
  background-color: rgba(5, 114, 210, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #606061;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 37px; /* 308.333% */
}

@media only screen and (max-width:440px){
  .edit-container {
    width: 98%;
    margin-bottom: 10px;
  }
}
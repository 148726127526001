
*,
::before,
::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Inter;
}


.homepage-container2 {
  /* position: fixed; */
  width: 100%;
  height: auto;
  min-height: 100vh;
  background-image: url("../../images/WaitListBackground2.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  overflow-x: hidden;
}

.contenedor {
  width: 85%;
  height: auto;
}

.title2 {
  color: #fff;
  font-family: Inter;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.title-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.title-line {
  width: 100px;
  height: 5px;
  background-color: white;
  margin-left: 70px;
}

.mt-280 {
  margin-top: 70px;
}

.line {
  width: 50px;
  height: 1px;
  background-color: #fff;
}

.title-container p {
  width: 90%;
  color: #fff;
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 100% */
}

.title-container ul li {
color: #FFF;
font-family: Inter;
font-size: 22px;
font-style: normal;
font-weight: 500;
line-height: 34px; /* 154.545% */
}

.title-container ul {
  width: 70%;
}

.second-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-container {
  width: 75%;
  height: auto;
  min-height: 400px;
  background-color: white;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.15);
  border-radius: 25px;
  padding: 15px 20px 15px 20px;
  margin-top: 50px;
}

.bee-logo-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
}

.form-title {
  color: #383e45;
  text-align: center;
  font-family: Inter;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
}

.label-input-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.label-title {
  color: #0572d2;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 10px;
}

.blue-line {
  background-color: #0572d2;
  width: 20%;
  margin-top: 5px;
  margin-bottom: 20px;
  height: 2px;
}

.message{
  color: #0572d2;
  font-family: Inter;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 99%;
  text-align: center;
}

.objetive-timer{
  margin-left: 60px;
}

@media only screen and (max-width:1399px){
  .title-container p {
    width: 70%;
    color: #fff;
    font-family: Inter;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }
  .title-container ul {

  }
}
@media only screen and (max-width: 991.98px) {
  .homepage-container2 {
    /* position: fixed; */
    width: 100%;
    height: auto;
    min-height: 100vh;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    overflow-x: hidden;
    padding-bottom: 20px;
  }
  .mt-280 {
    margin-top: 100px;
  }

  .title2 {
    text-align: center;
  }

  .title-line {
    margin-left: 135px;
  }

  .form-container {
    width: 100%;
  }

  .title-container ul {
    width: 100%;
  }

  .title-container p {
    margin-top: 10px;
    width: 100%;
  }

  .objetive-timer{
    margin-left: 0px;
  }
  
}

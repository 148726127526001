.stepper-princial-container {
  width: 95%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.stepper-header {
  width: 70%;
  height: auto;
  min-height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 90px;
  padding-top: 0px;
}

.circle-stepper {
  width: 42px;
  height: 40px;
  background-color: #0572d2;
  color: white;
  font-size: 1.4em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.stepper-line {
  width: 54px;
  height: 1px;
  margin-left: 8px;
  margin-right: 8px;
  background-color: #0572d2;
}

.step-card-container {
  width: 90%;
  height: auto;
  min-height: 450px;
  margin-bottom: 10px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.step-card-title {
  width: 100%;
  text-align: center;
  font-size: 33px;
  color: #383e45;
  font-family: Inter;
}

.step-card-line {
  background-color: #06b576;
  width: 57px;
  height: 3px;
  margin: 8px 0px;
  margin-bottom: 10px;
}

.step-card-radio-buttons {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-around;
  margin: 10px 0px;
}

.step-card-radio {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-around;
  flex-wrap: wrap;
}
.step-card-radio1 {
  font-size: 16px;
  font-family: Inter;
  color: #383e45;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.step-card-radio2 {
  font-size: 16px;
  font-family: Inter;
  color: #383e45;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.step-button-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.button-steper {
  width: 266px;
}

.google-autocomplete {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.suite-apt {
  width: 24%;
}

.search2 {
  position: relative;
  width: 90%;
}

.search2 button {
  position: absolute;
  top: 135px;
  transform: translateY(-50%);
}

.search2 button {
  right: 20px;
  left: auto;
  border: none;
  margin: 0;
  padding: 0;
  background: none;
  cursor: pointer;
}

.search2 input {
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 52px;
  border-radius: 5px;
  padding: 0 60px 0 10px;
  font-size: 18px;
  background: white;
}

.form-input-adjust {
  height: 52px;
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 52px;
  border-radius: 5px;
  padding: 0 60px 0 10px;
  font-size: 18px;
  background: white;
}

.predictions-place {
  position: absolute;
  margin-left: 1px;
  background-color: white;
  border: 1px solid #dee2e6;
  border-top: 0;
  border-bottom: 0;
  width: 99.5%;
  border-top: 0px;
  z-index: 9999;
  margin-top: 79px;
}

.prediction {
  padding: 4px 60px 4px 10px;
  font-size: 17px;
}

.prediction:hover {
  background-color: #dee2e6;
  cursor: pointer;
}

.nda-preview {
  background-color: #f3f3f3;
  width: 100%;
  height: 410px;
}

.type-signature-container {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 20px;
  position: relative;
}

.border-write-signature{
  width: 100%;
  height: 200px;
  border-radius: 5px;
  border: 1px dashed #363636;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.sigPad{
  border: 1px solid transparent;
}

.draw-signature-container {
  width: 100%;
  height: 200px;
  border-radius: 5px;
  background: white;
  font-family: Cherolina;
  font-size: 70px;
  display: flex;
  justify-content: center;
  align-items: center;;
}

.type-signature-container2 {
  margin-top: 10px;
  width: 100%;
  height: 200px;
  border-radius: 5px;
  border: 1px dashed #5b5b5b;
  background: white;
  font-family: Cherolina;
  font-size: 70px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
}

.type-signature-title {
  color: #0572d2;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 20px;
}

.btn-clear {
  font-family: Inter;
  position: absolute;
  margin-right: 10px;
  z-index: 5;
}

.step-first {
  width: 73%;
}

.step-second {
  width: 25%;
}

.step-third {
  width: 48%;
}
.step-fourth {
  width: 48%;
}

.iframe-document-stepper{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  padding-top: 7px;
  padding-bottom: 10px;
}

.iframe-document-stepper img{
  width: 97%;
  margin-top: 3px;
}

.input-subtitle{
color: #606061;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.entity-name-container{
  width: 100%;
}


@media only screen and (max-width:440px){
  .step-button-container{
  }

  .step-button-container button{
    width: 180px;
  }
  .nda-preview{
  }
  .nda-preview .iframe{
    background-color: transparent;
  }
}
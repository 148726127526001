.cavatar-principal-container {
  width: 100%;
  height: auto;
  min-height: 92.9vh;
  background-image: url("../../images/dashboard_background.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
  overflow-x: hidden;
}

.cavatar-main {
  width: 662px;
  height: 718px;
  border-radius: 25px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cavatar-title{
    color: #383E45;
    text-align: center;
    font-family: Inter;
    font-size: 33px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;  
}

.signup-avatar-img{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.green-line {
    width: 100px;
    height: 3px;
    background-color: #06b576;
    margin-bottom: 10px;
  }

  .cavatar-container{
    width: 90%;
  }

.cavatar-footer {
  width: 100%;
  height: auto;
  min-height: 60px;
  background-color: rgba(195, 217, 236, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #606061;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 37px;
  padding-top: 5px;
}

@media only screen and (max-width:630px){
  .cavatar-main{
    width: 95%;
  }
}

.crop-round .cropper-crop-box, .cropper-view-box {
  border-radius: 50%;
}

.crop-round .cropper-view-box {
  box-shadow: 0 0 0 1px #39f;
  outline: 0;
}
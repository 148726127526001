*,
::before,
::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Inter;
}
.term-fo-service-principal-container {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: auto;
  min-height: 100vh;
  background-image: url("../../images/termofservice-background.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 50px;
  align-items: center;
  overflow: auto;
  overflow-x: hidden;
}
.termsnda-container {
  width: 1500px;
  height: auto;
  min-height: 800px;
  border-radius: 25px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  margin-top: 50px;
  padding: 20px 50px;
}

.terms-title {
  color: #383e45;
  text-align: center;
  font-family: Inter;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
  margin-bottom: 10px;
}

.green-line-terms {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.green-line-term {
  width: 57px;
  height: 3px;
  border-radius: 5px;
  background: #06b576;
}

.terms-subtitle {
  color: #0572d2;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 100% */
  margin-top: 20px;
  margin-bottom: 10px;
  padding-left: 5px;
}

.terms-subtitle2 {
  color: #0572d2;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-left: 5px;
}

.terms-text {
  color: #606061;
  text-align: left;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  padding: 0; /* 120% */
}

.terms-text a {
  color: #0572d2;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
}

.terms-h3 {
  color: #0572d2;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-left: 35px;
}

ol li {
  color: #0572d2;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 20px;
}

ol li .terms-text {
  margin-top: 10px;
  width: 100%;
  margin-left: -20px;
}

.ul-list li {
  list-style: disc;
  color: #606061;
  text-align: justify;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 120% */
  width: 98%;
}

.before-footer {
  width: 100%;
  color: #606061;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-terms {
  width: 100%;
  min-height: 60px;
  background-color: rgba(5, 114, 210, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #606061;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 37px;
}

@media only screen and (max-width:1145px){
    .termsnda-container {
        width: 98%;
        height: auto;
        min-height: 800px;
        border-radius: 25px;
        background: #fff;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
        margin-top: 20px;
        padding: 20px 20px;
      }

      .terms-text {
        text-align: left;
      }

      .termsnda-container{
        box-shadow: none;
      }
}

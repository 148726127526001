.signup-card-container {
  width: 100%;
  height: auto;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 40px;
}

.signup-title {
  color: #383e45;
  text-align: center;
  font-family: Inter;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}

.signup-subtitle {
  color: #606061e2;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 100% */
  margin-bottom: 15px;
}

.logincard-line {
  width: 57px;
  height: 3px;
  background: #06b576;
}

.signupcard-from {
  width: 80%;
}

.green-line {
  width: 100px;
  height: 3px;
  background-color: #06b576;
  margin-bottom: 10px;
}

.signup-avatar-img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.plus-avatar {
  position: absolute;
  margin-top: 115px;
  margin-left: -120px;
  cursor: pointer;
}

.verify-password {
  color: #676767;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  padding-top: 5px;
}

.verify-subcontainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.verify-password2 {
  color: #676767;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  padding-top: 10px;
}

.input-file {
  background-color: #7acaff;
  box-sizing: border-box;
  border-radius: 50%;
  padding: 10px;
  object-fit: cover;
}

@media only screen and (max-width: 440px) {
  .signup-card-container {
    width: 100%;
  }
}

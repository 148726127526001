.sendNda-principal-container {
  width: 100%;
  height: auto;
  min-height: 100vh;
  background-image: url("../../images/background-sendNda.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.sendNda-logo-container {
  width: 100%;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sendNda-title {
  width: 100%;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 33px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.greenlineNda {
  border-radius: 5px;
  background: #06b576;
  width: 100px;
  height: 3px;
}

.send-nda-container {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.nda-footer {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.15);
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}

.send-nda-image-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.send-nda-select {
  width: 662px;
  height: 202px;
  border-radius: 25px;
  background: rgba(255, 255, 255, 0.11);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.send-nda-select-title {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 33px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.send-nda-select-text {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 200;
  line-height: 20px; /* 100% */
}

.send-nda-show-page {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  gap: 5px;
}

.label-input {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media only screen and (max-width:630px){
  .send-nda-select{
    width: 95%;
  }
}

@media only screen and (max-width: 440px) {
  .send-nda-container {
    height: auto;
  }
  .send-nda-select {
    width: 100%;
    height: 202px;
    margin-top: 20px;
  }
}

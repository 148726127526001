.docs-principal-container {
  width: 100%;
  height: auto;
  min-height: 92.8vh;
  background-image: url("../../images/dashboard_background.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
  overflow-x: hidden;
}

.main-doc-container {
  width: 100%;
  height: auto;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.nda-info-container2 {
  width: 640px;
  height: auto;
  min-height: 809px;
  border-radius: 25px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nda-info-title {
  color: #383e45;
  text-align: center;
  font-family: Inter;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 50px;
}

.info-greenline {
  width: 57px;
  height: 3px;
  border-radius: 5px;
  background: #06b576;
}

.info-tabs-conteiner {
  width: 70%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
  color: rgba(56, 62, 69, 0.5);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.info-tabs-conteiner:hover{
    cursor: pointer;
}

.documents-container{
    width: 80%;
    margin-top: 20px;
}

.doc-footer {
  width: 100%;
  height: auto;
  min-height: 60px;
  background-color: rgba(195, 217, 236, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #606061;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 37px;
  padding-top: 5px;
}

.container01{
  width: 100%;
height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}

@media only screen and (max-width:1350px){
  .nda-info-container2{
    width: 610px;
  }

  .documents-container{
    width: 85%;
  }
}

@media only screen and (max-width:440px){
  .nda-info-container2{
    width: 98%;
    margin-bottom: 10px;
  }

  .documents-container{
    width: 95%;
  }
}
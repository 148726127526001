::after,
::before,
a {
  text-decoration: none !important;
}

.other-card-container {
  width: 200px;
  height: 180px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  /* transition: transform 0.1s ease; */
}

/* .other-card-container:hover {
  transform: scale(1.009);
} */

.other-img{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box-model {
  width: 31px;
  height: 14px;
  border-radius: 0px 0px 5px 5px;
}

.box-model-footer {
  width: 100%;
  height: 10px;
  border-radius: 0px 0px 5px 5px;
}

.other-line-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
}

.other-line {
  width: 42px;
  height: 1px;
}

.text-center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0px 2px;
  text-decoration: none;
  padding: 0px 2px;
}

.text-center2 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-decoration: none;
    padding: 0px 2px;
  }

  @media screen and (max-width: 768px) {
    .other-card-container {
      width: 180px;
      padding: 0px 5px;
    }
  }
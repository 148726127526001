*,
::before,
::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Inter;
}

.download-principal-container {
  width: 100%;
  height: auto;
  min-height: 100vh;
  background-image: url("../../images/DownloadWeb-Background.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
  overflow-x: hidden;
}

.download-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.download-principla-logo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
}

.download-principla-logo img {
}

.download-info-container {
  width: 600px;
  height: auto;
  min-height: 400px;
  padding-top: 50px;
  padding-bottom: 40px;
}

.label-title-download {
  color: white;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 10px;
}

.div-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.download-title {
  width: 100%;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 34px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 20px;
}

.download-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.download-button {
  width: 300px;
  height: 81px;
  border-radius: 30px;
  border: 2px solid white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button-download-title {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.whiteline-download{
  width: 100px;
  height: 2px;
  background-color: white;
  margin-top: 5px;
}

.img-button-container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.download-button-container2 {
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.download-button-container2 div {
  width: 300px;
  background-color: white;
  color: #0572d2;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 15px;
}

.download-button-container2 div:hover {
  background-color: rgb(232, 232, 232);
}

.download-button-container img:hover {
  filter: drop-shadow(1px 1px 1px #08cc84);
}

.div-form label,
.div-form input {
  width: 80%;
}

.phone-input {
  width: 80%;
}

.button-download {
  width: 100%;
  height: 82px;
  border-radius: 50px;
  background: #06b576;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}

.button-download2 {
  width: 100%;
  min-height: 82px;
  height: auto;
  border-radius: 50px;
  /* background: #06b576; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 20px;
}

.button-download3 {
  width: 100%;
  height: 82px;
  border-radius: 50px;
  /* background: #06b576; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}

.download-title {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.download-title1 {
  color: rgb(227, 222, 222);
  text-align: center;
  font-family: Inter;
  font-weight: 100;
  font-size: 30px;
  font-style: normal;
  line-height: normal;
}

.download-title2 {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.download-line {
  width: 144px;
  height: 1px;
  background: #fff;
}

.download-nda {
  width: 90%;
  height: auto;
  min-height: 45px;
  border-radius: 50px;
  background: #06b576;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.download-application-title {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.download-footer {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 50px;
  color: #ffffffb8;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-left: 30px;
  padding-bottom: 10px;
}

@media screen and (max-width: 600px) {
  .download-info-container {
    width: 98%;
  }
  .download-footer {
    text-align: center;
  }

  .download-principal-container {
    background-image: url("../../images/Download-Mobile.png");
  }

  .button-download {
    width: 100%;
    border-radius: 0px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 420px) {
  .download-info-container {
    width: 98%;
  }
  .download-footer {
    text-align: center;
  }

  .download-title{
    padding-bottom: 15px;
    padding-top: 15px;
  }

  .download-principal-container {
    background-image: url("../../images/Download-Mobile.png");
  }

  .button-download {
    width: 100%;
    border-radius: 0px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .download-title{
    padding-bottom: 15px;
    padding-top: 15px;
  }

  .button-download2 {
    font-size: 20px;
  }

  .download-footer{
    display: flex;
    justify-content: space-between;
    padding-left: 0px;
    padding-bottom: 0px;
  }

  .margin-title{
    margin-top: 50px;
    margin-bottom: 15px;
  }
}

.principal-container-alerts {
  width: 100%;
  height: auto;
  min-height: 92.8vh;
  background-image: url("../../images/dashboard_background.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
  overflow-x: hidden;
}

.alerts-main-container {
  width: 640px;
  height: auto;
  min-height: 700px;
  border-radius: 25px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  padding: 5px 20px;
}

.alerts-title {
  color: #383e45;
  text-align: center;
  font-family: Inter;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
}

.alerts-greenline-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.alert-greenline {
  width: 70px;
  height: 3px;
  border-radius: 5px;
  background: #06b576;
  margin-top: 5px;
}

.alert-container {
  width: 100%;
  height: 650px;
  overflow-y: auto;
  overflow-x: hidden;
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 30px;
}

.alerts-footer{
    width: 100%;
    height: auto;
    min-height: 60px;
    background-color: rgba(195, 217, 236, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #606061;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 37px;
    padding-top: 5px;
}

.status_container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin-top: 10px;
}

.status_container span {
color: #383E45;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
cursor: pointer;
}

@media only screen and (max-width:450px){
  .alerts-main-container {
    width: 100%;
    height: auto;
    min-height: 400px;
    border-radius: 25px;
    background: #fff;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    padding: 5px 20px;
  }

  .alert-container {
    width: 100%;
    height: 650px;
    overflow-y: auto;
    overflow-x: hidden;
    gap: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 30px;
  }
}
